import React from "react";
import style from './error419.module.css';

const Error419 = () => {
    return (<>
        <div className={`${style['html']}`}>
            <div className={`${style['body']}`}>
                <div className={`${style['section']}`}>
                    {/* <h1 className={`${style['error']}`}><i class="fa-solid fa-triangle-exclamation"></i></h1> */}
                    <h1 className={`${style['error']}`}><i className="fa-solid fa-link-slash"></i></h1>
                    
                    <div className={`${style['page']}`}>It looks like the payment link you tried to use has expired. For security reasons, payment links are typically valid for a limited time. Please request a new link or contact our support team for assistance.</div>
                    {/* <a className={`${style['back-home']}`} href="#!">Back to home</a> */}
                </div>
            </div>
        </div>
    </>);
}

export default Error419;