import React from "react";
import style from './error.module.css';

const Error = () => {
    return (<>
        <div className={`${style['html']}`}>
            <div className={`${style['body']}`}>
                <div className={`${style['section']}`}>
                    <h1 className={`${style['error']}`}>Ooops!!! Something went wrong!</h1>
                    
                    <div className={`${style['page']}`}>
                        We are looking for fix it. Please try again in a few minutes.
                    </div>
                    {/* <a className={`${style['back-home']}`} href="#!">Back to home</a> */}
                </div>
            </div>
        </div>
    </>);
}

export default Error;