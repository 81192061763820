import React from "react";
import style from './payment-success.module.css'
const PaymentSuccess = () => {
    return (
        <>
            <div className={`${style['html']}`}>
                <div className={`${style['body']}`}>
                    <div className={`container`}>
                        <div className={`row justify-content-center`}>
                            <div className={`col-md-5`}>
                                <div className={`${style['message-box']} ${style['_success']}`}>
                                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                                    <h2> Your payment was successful </h2>
                                    <p> Thank you for your payment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentSuccess;