import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PaymentSwitchSDK from '@inspira-npm/pg-switch-js';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './Invoice.module.css';
import Error404 from "./error-page/error404";
import Error419 from "./error-page/error419";
import Error from "./error-page/error";
import PaymentSuccess from "./payment/success/payment-success";


const env = {
    uat: {
        publicKey: 'pk_test_09bf655ff5b950ea4fd95328302bc896e83d7a0725a2adfffbf7e046f62bbd38',
        url: 'https://uat-routen.codestack.ae:3000',
    },
    dev: {
        publicKey: 'pk_test_ca15a5c779c6444d33ed8d0188a15382a0e962c5c876ca44a25edc2d91d2415f',
        url: 'https://dev-routen.codestack.ae:3000',
    },
    prod: {
        publicKey: 'pk_live_118af5fd0d6adba1150508c8ea2564fad3881beb83dc33a73d59f2c8f49e6caa',
        // publicKey: 'pk_test_ab4ae2a2560662a4eed135e81461035be5a1c0d96190650a35c21e4ba48c3488',
        url: 'https://inspirapay.com:3000',
    },
    local: {
        publicKey: '',
        url: 'http://localhost:3000'
    }
}
const environment = 'uat'
const publicKey = env[environment].publicKey;
const url = env[environment].url;
const pgSwitchSDK = new PaymentSwitchSDK(
    url,
    publicKey,
    'API_SECRET_1',
);

const calculateCounter = (date) => {
    // console.log('Expiry Date',date);
    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;
    let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = (date.getMonth() + 1) + "/" + date.getDate() + "/",
        expiryDate = dayMonth + yyyy;

    today = mm + "/" + dd + "/" + yyyy;
    if (today > expiryDate) {
        expiryDate = dayMonth + nextYear;
    }

    const countDown = new Date(date).getTime();


    const now = new Date().getTime(),
        distance = countDown - now;
    // console.log(now,countDown,distance);
    // document.getElementById("days").innerText = Math.floor(distance / (day)),
    //   document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour)),
    //   document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute)),
    //   document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second);

    //do something later when date is reached
    if (distance < 0) {
        return [0, 0, 0, 0];
    }
    return [
        Math.floor(distance / (day)),
        ('0' + Math.floor((distance % (day)) / (hour))).slice(-2),
        ('0' + Math.floor((distance % (hour)) / (minute))).slice(-2),
        ('0' + Math.floor((distance % (minute)) / second)).slice(-2),
    ];
}
const Invoice = () => {
    const { id } = useParams();
    const [invoiceData, setInvoiceData] = useState(null);
    const [status, setStatus] = useState(false);
    const [counter, setCounter] = useState([-1, -1, -1, -1]);
    useEffect(() => {
        if (status === 'error') {
            document.title = 'Error'
        } else if (status === 'notFound') {
            document.title = 'Invoice not found!'
        } else if (status === 'expired') {
            document.title = 'Invoice is expired!'
        } else if (status === 'payment_success') {
            document.title = 'Payment Successful.';
        } else {
            document.title = 'Invoice Payment.'
        }
    }, [status]);
    useEffect(() => {
        (async () => {
            if (id) {
                let paymentElement = document.getElementById('payment-element');
                let buttonElement = document.getElementById('submit');
                let result = await pgSwitchSDK.getInvoiceDetails(id, paymentElement, buttonElement);
                if (result) {
                    if (result.code === 419) {
                        setStatus('expired');
                    } else if (result.code === 404) {
                        setStatus('notFound');
                    } else if (result.code === 208) {
                        setStatus('payment_success');
                    } else if (result.code > 210) {
                        setStatus('error');
                    } else {
                        pgSwitchSDK.apiKey = result.invoiceDetails?.public_key;
                        setInvoiceData(result);
                        const intervalId = setInterval(function () {
                            const r = calculateCounter(new Date(result?.invoiceDetails?.payment_link_expiry_date));
                            if (parseInt(r[0].toString()) === 0 && parseInt(r[1].toString()) === 0 && parseInt(r[2].toString()) === 0 && parseInt(r[3].toString()) === 0) {
                                clearInterval(intervalId);
                                setStatus('expired');
                            }
                            setCounter(r)
                        }, 0);
                    }
                }
            } else {
                console.log('ID not found.');
            }
        })();
    }, []);
    const handlePayClick = async (e) => {
        if (new Date(invoiceData?.invoiceDetails?.payment_link_expiry_date).getTime() < new Date().getTime()) {
            // setStatus('expired');
        } else {
            let result = await pgSwitchSDK.handlePayClick(e);
            console.log(result);
        }
    }
    return (
        <> {
            status === 'notFound' ? <>
                <Error404 />
            </> :
                status === 'error' ? <>
                    <Error />
                </> :
                    status === 'expired' ?
                        <>
                            <Error419 />
                        </> :
                        status === 'payment_success' ? <>
                            <PaymentSuccess />
                        </> : <>
                            <div className={styles['invoice-box']}>
                                <table>
                                    <tbody>
                                        <tr className={styles.top}>
                                            <td colSpan="2">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className={styles.title}>
                                                                {invoiceData?.invoiceDetails?.organization_id?.logo ? <img src={invoiceData?.invoiceDetails?.organization_id?.logo} alt="" style={{ width: "100%", maxWidth: "100px" }} /> : <Skeleton width='100px' />}
                                                            </td>
                                                            <td>
                                                                {
                                                                    invoiceData?.invoiceDetails?.invoice_no ? <>
                                                                        Invoice #: {invoiceData?.invoiceDetails?.invoice_no}
                                                                        <br />
                                                                    </> : <Skeleton width='250px' />
                                                                }
                                                                {
                                                                    invoiceData?.invoiceDetails?.invoice_date ? <>
                                                                        Invoice Date : {format(invoiceData?.invoiceDetails?.invoice_date, 'yyyy-MM-dd HH:mm')}
                                                                        <br />
                                                                    </> : <Skeleton width='250px' />
                                                                }
                                                                {
                                                                    invoiceData?.invoiceDetails?.payment_link_expiry_date ?
                                                                        <>Expired On : {format(invoiceData?.invoiceDetails?.payment_link_expiry_date, 'yyyy-MM-dd HH:mm')}
                                                                            <br />
                                                                        </> : <Skeleton width='250px' />
                                                                }
                                                                {
                                                                    invoiceData?.invoiceDetails?.payment_link_expiry_date ?
                                                                        <>

                                                                            <div id={`${styles['countdown']}`}>
                                                                                <ul>
                                                                                    <li>Expires In :</li>
                                                                                    {counter[0] > 0 ? <li><span id="days">{counter[0]}</span>D</li> : null}
                                                                                    {counter[1] > 0 ? <li><span id="hours">{counter[1]}</span> H</li> : null}
                                                                                    {counter[2] > 0 ? <li><span id="minutes">{counter[2]}</span> M</li> : null}
                                                                                    <li><span id="seconds">{counter[3]}</span> S</li>
                                                                                </ul>
                                                                            </div>
                                                                        </> : <Skeleton width='250px' />
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr className={styles.information}>
                                            <td colSpan="2">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                            </td>
                                                            <td className={styles['title_case']}>
                                                                {
                                                                    invoiceData?.invoiceDetails?.customer ?
                                                                        <> <div className={`${styles['title_case']} d-inline-block`}> {invoiceData?.invoiceDetails?.customer?.salutation}&nbsp;</div>
                                                                            <div className={`${styles['title_case']} d-inline-block`}> {invoiceData?.invoiceDetails?.customer?.first_name}&nbsp;</div>
                                                                            <div className={`${styles['title_case']} d-inline-block`}> {invoiceData?.invoiceDetails?.customer?.last_name}</div><br />
                                                                            {invoiceData?.invoiceDetails?.customer?.email}<br />
                                                                            {invoiceData?.invoiceDetails?.customer?.mobile_number}
                                                                        </> : <Skeleton width='250px' count={3} />
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr className={styles["heading"]}>
                                            <td>Item</td>
                                            <td>Price</td>
                                        </tr>
                                        {
                                            invoiceData?.invoiceDetails?.item && invoiceData?.invoiceDetails?.item.length > 0 ? <>
                                                {
                                                    invoiceData?.invoiceDetails?.item.map((item, index) => (
                                                        <tr key={index} className={`${styles['item']} ${index + 1 === invoiceData?.invoiceDetails?.item.length ? styles['last'] : ''}`}>
                                                            <td>{
                                                                invoiceData.invoiceDetails.is_html ? <>
                                                                    <div dangerouslySetInnerHTML={{ __html: item.item_name }} />
                                                                </> : item.item_name
                                                            }</td>
                                                            <td>{invoiceData?.invoiceDetails?.currency} {item.amount}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </> :
                                                <>
                                                    <tr className={styles["item"]}>
                                                        <td><Skeleton /></td>
                                                        <td><Skeleton /></td>
                                                    </tr>
                                                    <tr className={styles["item"]}>
                                                        <td><Skeleton /></td>
                                                        <td><Skeleton /></td>
                                                    </tr>
                                                    <tr className={styles["item"]}>
                                                        <td><Skeleton /></td>
                                                        <td><Skeleton /></td>
                                                    </tr>
                                                </>
                                        }
                                        <tr className={styles["total"]}>
                                            <td></td>
                                            <td>Total: {invoiceData?.invoiceDetails?.currency} {invoiceData?.invoiceDetails?.total_amount || 0} </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={`${styles["w-100"]} ${styles["py-4"]}`}>
                                                    <div id="payment-element"></div>
                                                    <br />
                                                    {
                                                        invoiceData?.invoiceDetails?.terms_condition
                                                            ? <div dangerouslySetInnerHTML={{ __html: invoiceData.invoiceDetails.terms_condition }} />
                                                            : <Skeleton width={'100%'} count={3} />
                                                    }

                                                    <button className={"btn btn-primary mb-2 mt-2"} id="submit" type="button" onClick={handlePayClick}>
                                                        <div className="spinner hidden" id="payment-spinner"></div>
                                                        <span id="payment-button-text">Pay now</span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div >
                        </>
        }
        </>
    )
}
export default Invoice;