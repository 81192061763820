// import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Invoice from "./pages/Invoice";
import DefaultPage from "./DefaultPage";
import Error404 from "./pages/error-page/error404";

export default function App() {
    
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<DefaultPage />} />
                <Route path="invoice/:id" element={<Invoice />} />
                <Route
                    path="*"
                    element={<Error404 />}
                />
            </Routes>
        </BrowserRouter>
    );
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);